import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <span className="logo-text">Lana's Nails</span>
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <div className={isMenuOpen ? 'nav-elements active' : 'nav-elements'}>
          <ul className="navbar-menu">    
            <li className="spaced-item"><Link to="/menu" onClick={toggleMenu}>שירותים ומחירים</Link></li>    
            <li className="spaced-item"><Link to="/" onClick={toggleMenu}>דף הבית</Link></li>
            <li className="spaced-item"><Link to="/about" onClick={toggleMenu}>עלינו</Link></li>
            <li className="spaced-item"><Link to="/contact" onClick={toggleMenu}>צור קשר</Link></li>
          </ul>
          <div className="navbar-info">
            <div className="navbar-phone">
              <a href="tel:+9723271187">072-327-1187</a>
            </div>
            <div className="navbar-location">
              סוקולוב 45, הרצליה
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;