import React from 'react';
import './AboutUs.css';
import salonImage from '../../Media/about1.jpg'; // Adjust the path as needed

const AboutUs = ({ isPreview = false }) => {
  return (
    <section className="about-us">
      
      <div className="about-us-content">
        <div className="about-us-text">
          <h2>מי אנחנו</h2>
          <p>
            ברוכים הבאים לסלון הציפורניים שלנו, המקום המושלם לטיפוח ויופי. אנו מתמחים במתן חוויית יופי מפנקת ואיכותית, תוך שימוש במוצרים הטובים ביותר ובטכניקות המתקדמות ביותר.
          </p>
          {!isPreview && (
            <>
              <p>
                הצוות שלנו מורכב ממומחי ציפורניים מנוסים ומוסמכים, המחויבים להעניק לכם את השירות הטוב ביותר. אנו מאמינים שכל לקוח הוא ייחודי, ולכן אנו מתאימים את הטיפולים שלנו לצרכים והרצונות האישיים של כל אחד ואחת.
              </p>
              <p>
                בסלון שלנו, אנו שמים דגש על היגיינה, נוחות ואווירה נעימה. אנו מזמינים אתכם להגיע ולהתפנק בחוויה מרגיעה ומעצימה, שתשאיר אתכם מרוצים ויפים.
              </p>
            </>
          )}
        </div>
        <div className="about-us-image">
          <img src={salonImage} alt="הסלון שלנו" />
        </div>
      </div>
      
    </section>
  );
};

export default AboutUs;