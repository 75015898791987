import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';

const Menu = ({ isPreview = false }) => {
  const services = [
    { category: "מניקור", items: [
      { name: "מניקור קלאסי", price: 110 },
      { name: "מניקור ג'ל", price: 175 },
      { name: "מילוי ציפורניים", price: 275 },
    ]},
    { category: "פדיקור", items: [
      { name: "פדיקור קלאסי", price: 185 },
      { name: "פדיקור ספא", price: 235 },
      { name: "פדיקור ג'ל רפואי", price: 285 },
    ]},
    { category: "בניית ציפורניים", items: [
      { name: "בנייה באקריל", price: 200 },
      { name: "בנייה בג'ל", price: 180 },
      { name: "תיקון ציפורן", price: 30 },
    ]},
    { category: "עיצובים מיוחדים", items: [
      { name: "ציור אומנותי", price: 25 },
      { name: "אבני סברובסקי", price: 35 },
      { name: "מדבקות מעוצבות", price: 12 },
    ]},
  ];

  const displayedServices = isPreview ? services.slice(0, 2) : services;

  return (
    <div className="menu-container">
      <h1 className="menu-title">תפריט שירותים ומחירים</h1>
      {displayedServices.map((category, index) => (
        <div key={index} className="menu-category">
          <h2 className="category-title">{category.category}</h2>
          <ul className="service-list">
            {category.items.map((item, itemIndex) => (
              <li key={itemIndex} className="service-item">
                <span className="service-name">{item.name}</span>
                <span className="service-price">₪{item.price}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {isPreview && (
        <div className="see-more-container">
          <Link to="/menu" className="see-more-button">לתפריט המלא</Link>
        </div>
      )}
    </div>
  );
};

export default Menu;