import React from 'react';
import './OfferingsSection.css';
import classicNails from '../../Media//man9.jpg';
import classicNailsHover from '../../Media/man2.jpg';
import gelNails from '../../Media/man3.jpg';
import gelNailsHover from '../../Media/man4.jpg';
import artNails from '../../Media/man5.jpg';
import artNailsHover from '../../Media/man6.jpg';

const OfferingsSection = () => {
  return (
    <section className="offerings-section">
      <h2>מה אנחנו מציעים</h2>
      <p className="offerings-description">
        אנו מתמחים במגוון רחב של טיפולי ציפורניים באיכות גבוהה
      </p>
      <p className="offerings-description">
        צוות המומחים שלנו ישמח להעניק לך את הטיפול המושלם עבורך
      </p>
      
      <div className="offerings-container">
        <div className="offering-item">
          <div className="image-container">
            <img src={classicNails} alt="מניקור קלאסי" className="main-image" />
            <img src={classicNailsHover} alt="מניקור קלאסי - דוגמא" className="hover-image" />
          </div>
          <h3>מניקור קלאסי</h3>
          <p>טיפול יסודי לציפורניים טבעיות, כולל עיצוב, שיוף וצביעה בלק רגיל</p>
        </div>
        
        <div className="offering-item">
          <div className="image-container">
            <img src={gelNails} alt="לק ג'ל" className="main-image" />
            <img src={gelNailsHover} alt="לק ג'ל - דוגמא" className="hover-image" />
          </div>
          <h3>לק ג'ל</h3>
          <p>ציפוי ג'ל עמיד ומבריק, מגן על הציפורן ומחזיק מעמד עד 3 שבועות</p>
        </div>
        
        <div className="offering-item">
          <div className="image-container">
            <img src={artNails} alt="עיצובים אומנותיים" className="main-image" />
            <img src={artNailsHover} alt="עיצובים אומנותיים - דוגמא" className="hover-image" />
          </div>
          <h3>עיצובים אומנותיים</h3>
          <p>עיצובים ייחודיים ומרהיבים לפי בחירתך, כולל הדפסים, אבני חן ועוד</p>
        </div>
      </div>
    </section>
  );
};

export default OfferingsSection;