import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Lana's Nails</h3>
          <p>הסלון המוביל לטיפוח ציפורניים</p>
        </div>
        <div className="footer-section">
          <h3>ניווט מהיר</h3>
          <ul>
            <li><Link to="/">דף הבית</Link></li>
            <li><Link to="/about">עלינו</Link></li>
            <li><Link to="/menu">שירותים</Link></li>
            <li><Link to="/contact">צור קשר</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>שעות פעילות</h3>
          <p>ראשון - חמישי: 10:00 - 20:00</p>
          <p>שישי: 10:00 - 14:00</p>
          <p>שבת: סגור</p>
        </div>
        <div className="footer-section">
          <h3>צור קשר</h3>
          <p><i className="fas fa-map-marker-alt"></i> סוקולוב 45, הרצליה</p>
          <p><i className="fas fa-phone"></i> 072-123-4567</p>
          <p><i className="fas fa-envelope"></i> info@lanasnails.com</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>  האתר עוצב ונבנה על ידי   <a href="https://codesuits.com" target="_blank" >CodeSuits</a></p>
        
      </div>
    </footer>
  );
};

export default Footer;