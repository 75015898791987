import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import OfferingsSection from './components/OfferingsSection/OfferingsSection';
import AboutUs from './Pages/AboutUs/AboutUs';
import Menu from './Pages/Menu/Menu';
import ContactPage from './Pages/ContactPage/ContactPage';
import Footer from './components/Footer/Footer';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize('G-8Y6QJM7V4J');

// Create a Home component that combines HeroSection and OfferingsSection
const Home = () => (
  <>
    <HeroSection />
    <OfferingsSection />
    <AboutUs isPreview={true} />
    <Menu />
    <Footer/>
  </>
);

// Create a component to track page views
const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <PageViewTracker />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;