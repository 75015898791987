import React, { useState } from 'react';
import './ContactPage.css';

const ContactPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the form submission,
    // like sending the data to a server
    
    // Show the popup
    setShowPopup(true);
    
    // Clear the form data
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
    });

    // Hide the popup after 3 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  return (
    <div className="contact-page">
      <h1 className="contact-title">צור קשר</h1>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Lana's Nails</h2>
          <p><i className="fas fa-map-marker-alt"></i> סוקולוב 45, הרצליה</p>
          <p><i className="fas fa-phone"></i> <a href="tel:+972123456789">072-123-4567</a></p>
          <p><i className="fas fa-envelope"></i> <a href="mailto:info@lanasnails.com">info@lanasnails.com</a></p>
          <p><i className="fas fa-clock"></i> שעות פעילות:</p>
          <p className="hours">א'-ה': 10:00-20:00</p>
          <p className="hours">ו': 10:00-14:00</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>שלחו לנו הודעה</h2>
          <input 
            type="text" 
            name="name" 
            placeholder="שם מלא" 
            required 
            value={formData.name}
            onChange={handleChange}
          />
          <input 
            type="email" 
            name="email" 
            placeholder="כתובת אימייל" 
            required 
            value={formData.email}
            onChange={handleChange}
          />
          <input 
            type="tel" 
            name="phone" 
            placeholder="מספר טלפון" 
            value={formData.phone}
            onChange={handleChange}
          />
          <textarea 
            name="message" 
            placeholder="ההודעה שלך" 
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button type="submit">שלח</button>
        </form>
      </div>
      {showPopup && (
        <div className="popup">
          <p>הודעתך נשלחה בהצלחה!</p>
        </div>
      )}
    </div>
  );
};

export default ContactPage;