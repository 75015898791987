import React from 'react';
import './HeroSection.css';
import heroImage from '../../Media/mini1.jpg'; // Make sure to add your image

const HeroSection = () => {
  return (
    <div className="hero-section">
      <img src={heroImage} alt="Nail Salon Interior" className="hero-image" />
      <div className="hero-text">
         <h1>ברוכים הבאים לסלון הציפורניים של לנה</h1>
            <p>חווית יופי ופינוק בקצות אצבעותיך</p>
      </div>
    </div>
  );
};

export default HeroSection;